import { Button, useBoolean } from "@chakra-ui/react";

export default function CopyButton(props: { text: string }) {
  const [copied, setCopied] = useBoolean(false);
  return (
    <Button
      size="sm"
      variant={"ghost"}
      colorScheme="blue"
      onClick={() => {
        setCopied.toggle();
        navigator.clipboard.writeText(props.text);
      }}
    >
      {copied ? "Copied" : "Copy Address"}
    </Button>
  );
}
