import { Box, Heading, Link } from "@chakra-ui/react";
import { MainNavBar } from "@/pages/main-layout";

const NoPage = () => {
  return (
    <Box>
      <MainNavBar />{" "}
      <Box p="20%">
        <Heading>The page you're looking for is missing...</Heading>
        <Link fontSize={"25px"} href="/">
          Back to homepage
        </Link>
      </Box>
    </Box>
  );
};
export default NoPage;
