import nifti, {
  NIFTI1,
  NIFTI2,
  decompress,
  isCompressed,
  isNIFTI,
  readHeader,
  readImage,
} from "nifti-reader-js";
import { useEffect, useRef, useState } from "react";
import NiftiCanvas from "./NiftiCanvas";
import { decompressSync } from "fflate";
import { RiArrowUpDownFill, RiArrowLeftRightFill } from "react-icons/ri";
import { FaCompressArrowsAlt, FaExpandArrowsAlt } from "react-icons/fa";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Icon,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderMarkProps,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
} from "@chakra-ui/react";
import Emphasis from "../base-components/Emphasis";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import Color from "../../interfaces/color";

interface NiftiViewerProps {
  /** NIFTI file */
  file: File | null;
  /**
   * Placeholder text to display when the file is null
   */
  placeholder?: React.ReactNode;
  enableColorPicker?: boolean;
}

export default function NiftiViewer(props: NiftiViewerProps) {
  //colors for the nifti viewer
  const [colors, setColors] = useState<Color[]>([
    { r: 0, g: 0, b: 0 },
    { r: 255, g: 255, b: 255 },
  ]);

  const [data, setData] = useState<ArrayBuffer>();
  const [sliderSettings, setSliderSettings] = useState({ min: 0, max: 0 });
  const [currentSlice, setCurrentSlice] = useState(0);
  const { file, placeholder } = props;
  const [NIFTIHeader, setNIFTIHeader] = useState<NIFTI1 | NIFTI2 | null>(null);
  const [NIFTIImage, setNIFTIImage] = useState<ArrayBuffer | null>(null);
  const [showMetadata, setShowMetadata] = useBoolean(false);
  const { isMobile } = useCheckMobileScreen();
  useEffect(() => {
    if (file) {
      readFile(file);
    }
  }, [file]);
  useEffect(() => {
    if (data === undefined) return;
    console.log(`Reading data`);
    var _data = data;
    // parse nifti
    if (isCompressed(_data)) {
      _data = decompressSync(new Uint8Array(data)).buffer;
    }

    if (!isNIFTI(_data)) return;

    var niftiHeader = readHeader(_data);
    if (niftiHeader === null) {
      console.warn("nifti header is null");
      return;
    }
    setNIFTIHeader(niftiHeader);
    setNIFTIImage(readImage(niftiHeader, _data));

    // set up slider
    var slices = niftiHeader.dims[3];
    setSliderSettings({
      max: slices - 1,
      min: 0,
    });
    //
    // slider.value = Math.round(slices / 2);
    // slider.oninput = function () {
    //   drawCanvas(canvas, slider.value, niftiHeader, niftiImage);
    // };

    // // draw slice
    // drawCanvas(canvas, slider.value, niftiHeader, niftiImage);
  }, [data]);

  function readFile(file: File) {
    console.log(`Reading file ${file.name}`);
    var blob: Blob | null = makeSlice(file, 0, file.size);
    if (blob == null) return;
    // return if there is no slices made
    var reader = new FileReader();

    reader.onloadend = function (evt: ProgressEvent) {
      const target = evt.target as FileReader;
      if (target && target.readyState === FileReader.DONE) {
        //readNIFTI(file.name, target.result as ArrayBuffer);
        setData(target.result as ArrayBuffer);
        //console.log(target.result)
      }
    };

    reader.readAsArrayBuffer(blob);
  }

  function showScanMetadataTable() {
    return (
      <TableContainer
        transition={"all 0.3s"}
        height={showMetadata ? "" : "120px"}
        overflowY={"scroll"}
        pb={5}
      >
        <Table>
          <Thead position="sticky" top={0}>
            <Tr bg="whiteAlpha.800">
              <Th>Metric</Th>
              <Th>
                Value
                <Tooltip
                  aria-label="View table metrics"
                  label={
                    showMetadata
                      ? "Collapse the table."
                      : "Expand the table to view scan metadata."
                  }
                  openDelay={500}
                >
                  <IconButton
                    variant={"ghost"}
                    colorScheme="blue"
                    onClick={setShowMetadata.toggle}
                    left={"30px"}
                    aria-label="Show Metadata"
                    icon={
                      <Icon
                        as={
                          showMetadata ? FaCompressArrowsAlt : FaExpandArrowsAlt
                        }
                      />
                    }
                  />
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Size (bytes)</Td>
              <Td>{NIFTIImage?.byteLength}</Td>
            </Tr>
            <Tr>
              <Td>Slices</Td>
              <Td>{sliderSettings.max}</Td>
            </Tr>
            <Tr>
              <Td>Metadata</Td>
              <Td>{NIFTIHeader?.dims[0]}</Td>
            </Tr>
            <Tr>
              <Td>Columns</Td>
              <Td>{NIFTIHeader?.dims[1]}</Td>
            </Tr>
            <Tr>
              <Td>Rows</Td>
              <Td>{NIFTIHeader?.dims[2]}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    );
  }
  const labelStyles: {
    [key in keyof SliderMarkProps]?: SliderMarkProps[key];
  } = {
    //mt: '5',
    ml: "-8",
    bg: "blue.500",
    color: "white",
    borderRadius: "full",
    w: "5",
    textAlign: "center",
  };

  function verticalSlider() {
    return (
      <Slider
        height={"500px"}
        min={sliderSettings.min}
        max={sliderSettings.max}
        defaultValue={0}
        orientation={"vertical"}
        direction="ltr"
        onChange={(value) => {
          setCurrentSlice(value);
        }}
      >
        {/* <SliderMark value={0} {...labelStyles}>
  0
</SliderMark>
<SliderMark value={sliderSettings.max} {...labelStyles}>
  {sliderSettings.max}
</SliderMark>
<SliderMark
  value={currentSlice}
  {...labelStyles}
>
  {currentSlice}
</SliderMark> */}
        <SliderTrack bg="blackAlpha.600">
          <SliderFilledTrack bg="whiteAlpha.600" />
        </SliderTrack>
        <SliderThumb boxSize={"5"} width={"30px"} h={"25px"} color="black">
          <HStack color="brand.red" flexDirection={"row"} spacing={1}>
            <RiArrowUpDownFill />
            <Text>{currentSlice}</Text>
          </HStack>
        </SliderThumb>
      </Slider>
    );
  }
  function horizontalSlider() {
    return (
      <Slider
        width={"300px"}
        min={sliderSettings.min}
        max={sliderSettings.max}
        defaultValue={0}
        orientation={"horizontal"}
        direction="ltr"
        onChange={(value) => {
          setCurrentSlice(value);
        }}
      >
        <SliderTrack bg="blackAlpha.600">
          <SliderFilledTrack bg="whiteAlpha.600" />
        </SliderTrack>
        <SliderThumb boxSize={"5"} width={"30px"} h={"25px"} color="black">
          <HStack color="brand.red" flexDirection={"row"} spacing={1}>
            <RiArrowLeftRightFill />
            <Text>{currentSlice}</Text>
          </HStack>
        </SliderThumb>
      </Slider>
    );
  }

  function setColor(color: Color, index: number) {
    var _colors = [...colors];
    _colors[index] = color;
    setColors(_colors);
  }

  function generateColorPickers() {
    return colors.map((color, index) => {
      return (
        <ColorPicker
          defaultColor={
            index === 0
              ? { r: 0, g: 0, b: 0 }
              : index === 1
              ? { r: 255, g: 255, b: 255 }
              : undefined
          }
          color={color}
          setColor={(color) => setColor(color, index)}
        />
      );
    });
  }

  return (
    <>
      <Stack direction={isMobile ? "column" : "row"}>
        <Center>
          {NIFTIHeader &&
            NIFTIImage &&
            (isMobile ? horizontalSlider() : verticalSlider())}
        </Center>
        <Box>
          {NIFTIHeader && NIFTIImage ? (
            <NiftiCanvas
              niftiheader={NIFTIHeader}
              niftiimage={NIFTIImage}
              slice={currentSlice}
              colors={colors}
            />
          ) : (
            <AspectRatio ratio={1} bg="black" maxW={"512px"} minW={"300px"}>
              <Center h="100%" p={5}>
                {placeholder}
              </Center>
            </AspectRatio>
          )}
        </Box>
      </Stack>
      <Accordion allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Adjust Colors
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            {generateColorPickers()}
            <ButtonGroup variant={"ghost"} colorScheme="blue">
              <Button
                onClick={() => {
                  setColors([...colors, { r: 255, g: 255, b: 255 }]);
                }}
              >
                Add a Color
              </Button>
              <Button
                onClick={() => {
                  if (colors.length <= 2) return;
                  var _colors = [...colors];
                  _colors.pop();
                  setColors(_colors);
                }}
              >
                Remove last Color
              </Button>
            </ButtonGroup>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* {showScanMetadataTable()} */}
    </>
  );
}

function readNIFTI(name: string, data: ArrayBuffer) {
  var canvas = document.getElementById("myCanvas");
  var slider = document.getElementById("myRange");
  var niftiHeader: NIFTI1 | NIFTI2 | null, niftiImage: ArrayBuffer;

  // parse nifti
  if (nifti.isCompressed(data)) {
    data = nifti.decompress(data);
  }

  if (!nifti.isNIFTI(data)) return;

  niftiHeader = nifti.readHeader(data);
  if (!niftiHeader) return;

  niftiImage = nifti.readImage(niftiHeader, data);

  // set up slider
  var slices = niftiHeader.dims[3];
  // slider.max = slices - 1;
  // slider.value = Math.round(slices / 2);
  // slider.oninput = function () {
  //   drawCanvas(canvas, slider.value, niftiHeader, niftiImage);
  // };

  // // draw slice
  // drawCanvas(canvas, slider.value, niftiHeader, niftiImage);
}

function makeSlice(file: File, start: number, length: number) {
  var fileType = typeof File;

  if (fileType === "undefined") {
    return null;
  }
  return file.slice(start, start + length);
}

// function handleFileSelect(evt) {
//   var files = evt.target.files;
//   readFile(files[0]);
// }

function ColorPicker(props: {
  setColor: (color: Color) => void;
  color: Color;
  defaultColor?: Color;
}) {
  const { setColor, color, defaultColor } = props;
  return (
    <Stack direction={"row"} spacing={5}>
      <Text>Red</Text>
      <Slider
        min={0}
        max={255}
        defaultValue={defaultColor ? defaultColor.r : 255}
        onChange={(value) => {
          setColor({ r: value, g: color.g, b: color.b });
          setColor({ r: value, g: color.g, b: color.b });
        }}
      >
        <SliderTrack>
          <SliderFilledTrack bg="red" />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <Text>Green</Text>
      <Slider
        min={0}
        max={255}
        defaultValue={defaultColor ? defaultColor.g : 255}
        onChange={(value) => {
          setColor({ r: color.r, g: value, b: color.b });
          setColor({ r: color.r, g: value, b: color.b });
        }}
      >
        <SliderTrack>
          <SliderFilledTrack bg="green" />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <Text>Blue</Text>
      <Slider
        min={0}
        max={255}
        defaultValue={defaultColor ? defaultColor.b : 255}
        onChange={(value) => {
          setColor({ r: color.r, g: color.g, b: value });
          setColor({ r: color.r, g: color.g, b: value });
        }}
      >
        <SliderTrack>
          <SliderFilledTrack bg="blue" />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Stack>
  );
}
