import { Box, Button, Center, Heading, Text, Spinner } from "@chakra-ui/react";
import NiftiViewer from "../nifti-viewer/NiftiViewer";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import Emphasis from "../base-components/Emphasis";
//TODO reimplement proper interface later
interface SPIRALDemoOutputProps {
  input: File | null;

  processingState: "processing" | "done" | "idle" | "error";
  onDone: (result: "done" | "error" | "done") => void;
}

interface SPIRALDemoOutputPropsNoUpload {
  input: null | { folderName: string; phase: number };
  processingState: "processing" | "done" | "idle" | "error";
  onDone: (result: "done" | "error" | "done") => void;
}
export default function SPIRALDemoOutput(props: SPIRALDemoOutputPropsNoUpload) {
  const toast = useToast();
  const [niiFile, setNiiFile] = useState<File | null>(null);
  const { processingState, onDone, input } = props;
  useEffect(() => {
    submitNII();
  }, [input, processingState]);
  //TODO reimplement later
  /**Submitting a single scan to the server */
  // function submitNII() {
  //   if (input && processingState === "processing") {
  //     fetch("/spiral/api/demo", {

  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/octet-stream"
  //       },
  //       body: input
  //     }).then((response) => {
  //       if (response.ok) {
  //         return response.blob();
  //       }
  //       else {
  //         toast({
  //           title: "Error processing file",
  //           description: "Please try again",
  //           status: "error",
  //           variant: "subtle",
  //         });
  //         throw new Error("Error processing file");

  //       }
  //     }).then((blob) => {
  //       setNiiFile(new File([blob], "output.nii"));
  //       onDone("done");
  //     }
  //     ).catch((error) => {
  //       console.log(error);
  //       onDone("error");
  //     }
  //     );
  //   }

  // }
  //NOTE currently, we are not submitting the image at all!
  function submitNII() {
    if (input && processingState === "processing") {
      fetch("/spiral/api/demo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          folderName: input.folderName,
          phase: input.phase,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            toast({
              title: "Error processing file",
              description: "Please try again",
              status: "error",
              variant: "subtle",
            });
            throw new Error("Error processing file");
          }
        })
        .then((blob) => {
          setNiiFile(new File([blob], "output.nii"));
          onDone("done");
        })
        .catch((error) => {
          console.log(error);
          onDone("error");
        });
    }
  }
  return (
    <>
      {" "}
      {processingState === "processing" ? (
        <LoadingView />
      ) : processingState === "error" ? (
        <Heading>Issue creating output</Heading>
      ) : (
        <NiftiViewer
          file={niiFile}
          placeholder={
            <Text color="white" textAlign={"center"}>
              You'll see the <Emphasis>SPIRAL</Emphasis> output here.
            </Text>
          }
        />
      )}
      <Center>
        <Button
          variant={"outline"}
          colorScheme="red"
          onClick={() => {
            onDone("done");
            setNiiFile(null);
          }}
        >
          Cancel
        </Button>
      </Center>
    </>
  );
}

function LoadingView() {
  return (
    <Box h="500px">
      <Heading fontStyle={"italic"}>Processing...</Heading>
      <Center>
        <Spinner size="xl" />
      </Center>
    </Box>
  );
}
