import React from "react";
import logo from "./logo.svg";

import { Box } from "@chakra-ui/react";

import { BsChevronCompactDown } from "react-icons/bs";
import { ImArrowRight2 } from "react-icons/im";
import UploadNifti from "./components/spiral-demo/UploadNifti";
import { useState } from "react";
import NiftiViewer from "./components/nifti-viewer/NiftiViewer";
import SPIRALInformation from "./components/content-components/SPIRALInformation";
import SPIRALDemoOutput from "./components/spiral-demo/SPIRALDemoOutput";
import MainNavBar from "./pages/main-layout/MainNavBar";
//TODO for now using browser router, but will need to switch to server side rendering

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "./pages/main-layout/MainLayout";
import LandingPage from "@/pages/LandingPage";
import AboutUsPage from "@/pages/AboutUsPage";
import SPIRALPageContent from "@/pages/SPIRALPageContent";
import NoPage from "@/pages/NoPage";
import SciencePageContent from "@/pages/SciencePageContent";
import Opportunities from "./pages/Opportunities";
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <NoPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/spiral",
        element: <SPIRALPageContent />,
        //hypothetically we add a loader function to pass data to the component
      },
      {
        path: "/about-us",
        element: <AboutUsPage />,
      },
      { path: "/science-and-media", element: <SciencePageContent /> },
      { path: "/opportunities", element: <Opportunities /> },
    ],
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
