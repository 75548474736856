import { useEffect, useState } from "react";

const useCheckMobileScreen = (): {
  isMobile: boolean;
  width: number;
  height: number;
} => {
  //the following is fine for web-loaded React
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return { isMobile: width <= 768, width: width, height: height };
};

export default useCheckMobileScreen;
