import {
  Stack,
  Center,
  Avatar,
  Text,
  Link,
  useBoolean,
  Collapse,
} from "@chakra-ui/react";
import { useState } from "react";
import { TeamMember } from "@/interfaces";
interface TeamMemberDisplayProps {
  imageSize: number;
  includeEmail?: boolean;
  contact: TeamMember;
}
export default function TeamMemberDisplay(props: TeamMemberDisplayProps) {
  const { contact, imageSize } = props;
  const [showBio, setShowBio] = useBoolean(false);
  const includeEmail = props.includeEmail ? props.includeEmail : false;
  return (
    <Stack
      bg="blackAlpha.800"
      boxShadow="md"
      p={"15px"}
      borderRadius={10}
      color="white"
      onClick={() => {
        if (contact.bio) {
          setShowBio.toggle();
        }
      }}
      _hover={
        //change cursor to pointer on hover
        contact.bio ? { cursor: "pointer" } : undefined
      }
    >
      <Collapse in={!showBio}>
        <Center>
          <Avatar
            w={`${imageSize}px`}
            h={`${imageSize}px`}
            src={contact.avatar}
          />
        </Center>
        <Text
          align="center"
          fontSize="2xl"
          fontWeight="bold"
          color="brand.blue"
        >
          {contact.name}
        </Text>
        <Text align="center" fontSize="xl">
          {contact.role}
        </Text>

        {includeEmail && (
          <Text align="center" fontSize="xl">
            {contact.email}
          </Text>
        )}
      </Collapse>
      <Collapse in={showBio}>
        <Text
          whiteSpace={"pre-line"}
          align="center"
          fontSize="2xl"
          fontWeight="bold"
          color="brand.blue"
        >
          {contact.name}
        </Text>
        <Text>{contact.bio ? contact.bio : " "}</Text>
        <Text textAlign={"center"}>
          {contact.personalSite ? (
            <Link href={contact.personalSite}> View Portfolio</Link>
          ) : (
            <> </>
          )}
        </Text>
      </Collapse>
    </Stack>
  );
}
