import {
  ButtonGroup,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Img,
  HStack,
  IconButton,
  Icon,
  ImageProps,
  ButtonProps,
  Link,
  Text,
  Stack,
  Box,
  SimpleGrid,
  GridItem,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  BoxProps,
  useToast,
} from "@chakra-ui/react";
import { BsChevronCompactDown, BsLinkedin } from "react-icons/bs";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
import { useRef } from "react";
import { FiMail } from "react-icons/fi";
import additionalContent from "@/_content/additional_content.json";
const menuButtonHeight = "70px";
type NavigationItem = {
  displayName: string;
  url: string;
};
const navigationItems: NavigationItem[] = [
  {
    displayName: "SPIRAL",
    url: "/spiral",
  },

  {
    displayName: "About Us",
    url: "/about-us",
  },
  {
    displayName: "Science & Media",
    url: "/science-and-media",
  },
  {
    displayName: "Opportunities",
    url: "/opportunities",
  },
];

const { linkedin, email } = additionalContent.links;
export default function MainNavBar() {
  const { isMobile, width } = useCheckMobileScreen();
  return (
    <>
      {isMobile ? (
        <MobileMenu navigationItems={navigationItems} />
      ) : (
        <DesktopMenu navigationItems={navigationItems} />
      )}
    </>
  );
}

function createMenuItems() {
  return navigationItems.map((item) => {
    return (
      <Button as={Link} href={item.url}>
        {item.displayName}
      </Button>
    );
  });
}
export function DesktopMenu(props: { navigationItems: NavigationItem[] }) {
  const { width } = useCheckMobileScreen();
  const { navigationItems } = props;
  const toast = useToast({ position: "top" });
  return (
    <HStack bg="white" pl="10%" pr="10%">
      <AndromedaLogoFull maxW="25%" maxH={menuButtonHeight} />
      <ButtonGroup
        variant="menu"
        height="70px"
        // height={menuButtonHeight}
        spacing={0}
        fontFamily={"heading"}
        justifyContent={"center"}
        alignItems={"center"}
        w={"100%"}
      >
        {createMenuItems()}

        <IconButton
          height={"100%"}
          w="50%"
          variant={"menu"}
          size="lg"
          onClick={async () => {
            //copy email to clipboard
            navigator.clipboard
              .writeText(email)
              .then(() => {
                toast({
                  title: "Email copied to clipboard",
                  status: "info",
                  duration: 1000,
                  isClosable: true,
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }}
          justifyContent={"center"}
          icon={<FiMail />}
          aria-label="Email"
          rel="noopener noreferrer"
        />
        <IconButton
          w="50%"
          aria-label="LinkedIn"
          height={"100%"}
          variant={"menu"}
          size="lg"
          as={Link}
          href={linkedin}
          justifyContent={"center"}
          icon={<BsLinkedin />}
          target="_blank"
          rel="noopener noreferrer"
        />
      </ButtonGroup>
    </HStack>
  );
}
export function MobileMenu(props: { navigationItems: NavigationItem[] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        ref={btnRef}
        onClick={onOpen}
        variant="menu"
        rightIcon={<BsChevronCompactDown />}
      >
        Menu
      </Button>
      <Drawer
        size={"xl"}
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg={"white"}>
          <DrawerCloseButton color="brand.blue" />
          <ButtonGroup orientation="vertical" variant="menu" w="100%" h="100%">
            <Button as={Link} href={"/"}>
              Home
            </Button>
            {createMenuItems()}

            {/* <Text
              color="brand.blue"
              textAlign={"center"}
              fontWeight={"semibold"}
            >
              Contact
            </Text> */}
            <Button
              textAlign={"center"}
              // height={menuButtonHeight}

              variant={"menu"}
              as={Link}
              href={email}
              justifyContent={"center"}
              leftIcon={<FiMail />}
              rel="noopener noreferrer"
            >
              Email
            </Button>

            <Button
              textAlign={"center"}
              // height={menuButtonHeight}

              variant={"menu"}
              as={Link}
              href={linkedin}
              justifyContent={"center"}
              leftIcon={<BsLinkedin />}
              target="_blank"
              rel="noopener noreferrer"
            >
              Linked In
            </Button>
          </ButtonGroup>
        </DrawerContent>
      </Drawer>
    </>
  );
}
export function AndromedaLogoButton(props: ButtonProps) {
  return (
    <IconButton
      icon={<Img src="logos/Andromeda_A_logo.png" />}
      aria-label="Andromeda Logo"
      _hover={{
        transition: "all 0.3s ease",
        scale: 1.1,
        color: "white", //"blackAlpha.700"
      }}
      _active={{
        transition: "all 0.3s ease",
        scale: 0.9,
      }}
      height={"max-content"}
      transition={"all 0.3s ease"}
      bg=""
      {...props}
    />
  );
}

export function AndromedaLogoText(props: ImageProps) {
  return <Img src="logos/Andromeda_text_logo.png" {...props} />;
}

export function AndromedaLogoFull(props: ImageProps) {
  return (
    <Img
      p={1}
      src="logos/Andromeda_toolbar_logo.png"
      {...props}
      style={{ cursor: "pointer" }}
      onClick={() => {
        window.location.href = "/";
      }}
    />
  );
}
