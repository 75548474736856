import { GridItem, Img, SimpleGrid } from "@chakra-ui/react";
import { create } from "domain";
import { ReactElement, useState } from "react";
import { JsxElement } from "typescript";
import Tilt from "react-parallax-tilt";
const ImageCascade = ({
  folderName,
  interactive,
}: {
  folderName: string;
  interactive: boolean;
}) => {
  const start = 1;
  const finish = 16;

  function createImages() {
    const elements: ReactElement[] = [];
    for (var i = start; i <= finish; i++) {
      const path = `${folderName}/${i}.png`;
      elements.push(
        <SliceImage path={path} index={i} interactive={interactive} />
      );
    }
    return elements;
  }
  return (
    <SimpleGrid columns={16 + 1} spacingX={"-100"} p={4} pl="10em">
      <GridItem></GridItem>
      {createImages()}
    </SimpleGrid>
  );
};

export default ImageCascade;

function SliceImage({
  index,
  path,
  interactive,
}: {
  index: number;
  path: string;
  interactive: boolean;
}) {
  const [isHovering, setIsHovering] = useState(index == 8 ? true : false);
  return (
    <GridItem
      ml="-200px"
      zIndex={isHovering ? 1000 : 100 - index}
      transform={
        isHovering
          ? `rotateY(0deg) rotateX(0deg) scale(1.5) `
          : `rotateY(45deg) rotateZ(5deg) translateY(${10}px)`
      }
      transition={"all 0.5s ease-in-out"}
      opacity={isHovering ? 1 : 0.8}
      // _hover={{
      //   //filter: "blur(0px)",
      //   opacity: 1,
      //   zIndex: 1000,
      //   // transform: "scale(1.5)",
      //   transform: "rotateY(-60deg)",
      //   transition: "all 0.5s ease-in-out",
      // }}
    >
      <Tilt
        tiltEnable={interactive}
        onEnter={() => setIsHovering(true)}
        onLeave={() => setIsHovering(false)}
        tiltReverse={true}
        tiltMaxAngleX={30}
        tiltMaxAngleY={30}
        // perspective={1000}
        scale={1.4}
        //glareEnable={true}
      >
        <Img src={path} />
      </Tilt>
    </GridItem>
  );
}
