import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Box, BoxProps } from "@chakra-ui/react";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

const SlideInSection = (
  props: BoxProps & {
    /**Direction the section ENTERS from */
    direction: "left" | "right";
  }
) => {
  const isMobile = useCheckMobileScreen();
  const { direction, children } = props;

  const animationVariants = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    hidden: {
      opacity: 0,
      x: direction == "left" ? -1000 : 1000,
      transition: { duration: 1 },
    },
  };
  return (
    <Box
      as={motion.div}
      initial={"hidden"}
      variants={animationVariants}
      animate={"visible"}
    >
      {children}
    </Box>
  );
};

export default SlideInSection;
