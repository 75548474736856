import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    //heading: `"Heebo", sans-serif`,
    //heading: `'Gelasio', serif`,
    //body: `'Open Sans', sans-serif`,
  },
  colors: {
    brand: {
      offBlue: "#0fb6db",
      offBlue_translucent: "#0fb6db88",
      purple: "#3840b5",
      blue: "#00a5c9",
      grey: "#cbcbcb",
      gray: "#cbcbcb",
      gray_translucent: "#cbcbcb88",
      darkBlue: "#006D8A",
      midnightGreen: "#00313D",
      gunmetal: "#002129",
    },
    uofc: {
      bg: "F7EDE2",
      highlight: "F6BD60",
      primary: "F28482",
      secondary: "F28482",
      contrast: "84A59D",
      red: "#D00424",
    },
  },
  components: {
    Text: {
      baseStyle: {
        color: "white",
      },
    },
    Header: {
      baseStyle: {
        color: "white",
      },
    },
    Link: {
      // The styles all button have in common
      baseStyle: {
        fontWeight: "bold",
        whiteSpace: "normal",
        borderRadius: "base", // <-- border radius is same for all variants and sizes
        _hover: {
          bg: "",
        },
      },
      // Two sizes: sm and md
      sizes: {
        sm: {
          fontSize: "sm",
          px: 4, // <-- px is short for paddingLeft and paddingRight
          py: 3, // <-- py is short for paddingTop and paddingBottom
        },
        md: {
          fontSize: "md",
          px: 6, // <-- these values are tokens from the design system
          py: 4, // <-- these values are tokens from the design system
        },
      },
      // Two variants: outline and solid
      variants: {
        subtle: {
          textDecoration: "underline",
          textDecorationColor: "brand.darkBlue",
          textUnderlineOffset: "2px",
          transition: "all 0.2s ease-in",

          _hover: {
            textUnderlineOffset: "5px",
            transition: "all 0.2s ease-in",
          },
        },
      },
    },
    Button: {
      variants: {
        menu: {
          bg: "white",
          color: "brand.blue",
          borderRadius: 0,
          _hover: {
            bg: "brand.offBlue",
            color: "white",
            textDecoration: "none",
          },
          height: "100%",
          w: "100%",
          pt: 1,
          pb: 1,
        },
        text: {
          fontSize: "inherit",
          margin: 0,
          p: 0,
          bg: "transparent",
          color: "brand.blue",
          borderRadius: 0,
          _hover: {
            bg: "transparent",
            color: "brand.offBlue",
            textDecoration: "underline",
          },
        },
      },
    },
  },
});

export default theme;
