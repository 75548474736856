import { Outlet } from "react-router-dom";
import MainNavBar from "./MainNavBar";
import { Box } from "@chakra-ui/react";
import Footer from "./Footer";
export default function MainLayout() {
  return (
    <Box
      bg={"black"}
      //backgroundImage={"mri-scans-of-the-brain-splash.png"}
      backgroundAttachment={"fixed"}
      backgroundPosition={"center"}
      backgroundSize={"cover"}
      backgroundRepeat={"no-repeat"}
    >
      <MainNavBar />
      <Outlet />
      <Footer />
    </Box>
  );
}
