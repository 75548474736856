import {
  Box,
  Button,
  Center,
  GridItem,
  Heading,
  Img,
  Link,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import Emphasis from "../base-components/Emphasis";
import additionalContent from "@/_content/additional_content.json";
import CTandSPIRALImageCascade from "./CTandSPIRALImageCascade";
import SpiralTM from "../base-components/SpiralTM";
const SPIRALInformation = () => {
  const { isMobile } = useCheckMobileScreen();
  const { width } = useCheckMobileScreen();
  const { patent } = additionalContent.links;
  return (
    <Box justifyContent={"center"} justifyItems={"center"} fontSize="20px">
      <Heading textAlign={"center"}>What is SPIRAL?</Heading>
      <Text
        pl={width > 900 ? "200px" : "10px"}
        pr={width > 900 ? "200px" : "10px"}
      >
        The <Emphasis>Simple Perfusion Reconstruction Algorithm </Emphasis>
        (SPIRAL) provides brain perfusion images from a single time resolved CTA
        acquisition, similar to a CT Perfusion acquistion.
      </Text>
      <br />
      <Tabs variant={"solid-rounded"}>
        <TabList
          justifyContent={"space-evenly"}
          flexDirection={isMobile ? "column" : "row"}
          color="brand.offBlue"
          pl={width > 900 ? "200px" : "10px"}
          pr={width > 900 ? "200px" : "10px"}
        >
          <Tab
            width={"80%"}
            borderColor={"white"}
            borderWidth={"2px"}
            ml="2"
            mr="2"
          >
            <Text fontSize={"20px"}>
              How are <SpiralTM /> maps generated?
            </Text>
          </Tab>
          <Tab
            width={"80%"}
            borderColor={"white"}
            borderWidth={"2px"}
            ml="2"
            mr="2"
          >
            <Text fontSize={"20px"}>
              How do <SpiralTM /> maps compare to other perfusion algorithms?
            </Text>
          </Tab>
          <Tab
            width={"80%"}
            borderColor={"white"}
            borderWidth={"2px"}
            ml="2"
            mr="2"
          >
            <Text fontSize={"20px"}>
              What are the advantages of <SpiralTM /> over conventional CT
              Perfusion?
            </Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <CTandSPIRALImageCascade />
            <Text
              pl={width > 900 ? "200px" : "10px"}
              pr={width > 900 ? "200px" : "10px"}
            >
              <SpiralTM /> uses data from a{" "}
              <Text as="b" color="brand.blue">
                three-phase
              </Text>{" "}
              CT Angiogram to identify key characteristics and features of the
              brain perfusion between each phase.
              <br />
              <br />
              These features are then used as input for a machine learning model
              that generates a perfusion map.{" "}
            </Text>
          </TabPanel>
          <TabPanel
            pl={width > 900 ? "200px" : "10px"}
            pr={width > 900 ? "200px" : "10px"}
          >
            <Img
              src="SPIRAL_sample_output_beautified.png"
              justifySelf={"center"}
              ml="auto"
              mr="auto"
            />{" "}
            <Text>
              Combining perfusion data from three time-resolved scans, means our
              output is less noisy, and more accurate than other perfusion
              algorithms.
              <br />
              <br />
              The result is a stunningly{" "}
              <Text as="b" color="brand.blue">
                simple
              </Text>{" "}
              and{" "}
              <Text as="b" color="brand.blue">
                easy to interpret
              </Text>{" "}
              perfusion map that can be used to support an assessment of the
              extent of ischemic core and penumbra in acute stroke patients.
            </Text>
          </TabPanel>
          <TabPanel
            pl={width > 900 ? "200px" : "10px"}
            pr={width > 900 ? "200px" : "10px"}
          >
            <Heading textAlign={"center"}>Time is Brain</Heading>
            <Text>
              CT Perfusion is always acquired using an{" "}
              <Text as="b" color="brand.blue">
                additional
              </Text>{" "}
              cine CTP acquisition, whereas we can provide the same information
              to a clinician from a helical CTA.
              <br />
              <br />
              This can save precious minutes during a stroke, drastically
              improving treatment outcome!
            </Text>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Center>
        <Link
          pl="auto"
          pr="auto"
          color={"brand.blue"}
          fontWeight={"semibold"}
          textAlign={"center"}
          href={patent}
          target="_blank"
          rel="noopener noreferrer"
          variant={"subtle"}
        >
          Click here to see our patent.
        </Link>
      </Center>
      <br />

      {/* <Text>Time is Brain</Text> */}
    </Box>
  );
};
export default SPIRALInformation;
