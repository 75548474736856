import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

interface Props {}
export default function Opportunities({}: Props) {
  return (
    <Stack bg="brand.gunmetal" p="10%" color="white">
      <Heading textAlign={"center"}>Join The Team</Heading>
      <Text
        whiteSpace={"pre-line"}
      >{`Andromeda Medical Imaging is always looking for talented and motivated individuals who want to work in the medical devices, software development and AI space.
We pride ourselves on transparency and a culture of learning and growth. 

Our compensation is less competitive, but we offer a flexible work environment and a chance to develop skills and really contribute to a project at the ground level.

We are a small team, so you will have the opportunity to contribute to a variety of tasks and learn new skills.`}</Text>
    </Stack>
  );
}

function OpportunityList() {
  return (
    <Tabs>
      <TabList w="100%">
        <Tab w="100%" fontSize={"25px"}>
          Internships
        </Tab>
        <Tab w="100%" fontSize={"25px"}>
          Full time
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Text>
            {`We are currently looking for interns for the following positions:`}
          </Text>
          <SimpleGrid columns={3} p="5">
            <GridItem>
              <OpportunityCard
                title="Software Development Intern"
                description={`
We are looking for a software development intern to work on our web application. The ideal candidate would have experience with React, Typescript, and Node.js. Experience with AWS and Docker is a plus.
            `}
              />
            </GridItem>
          </SimpleGrid>
        </TabPanel>
        <TabPanel>
          <Text>{`There is currently nothing available. Check back soon!`}</Text>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

interface OpportunityCardProps {
  title: string;
  description: string;
}
export function OpportunityCard({ title, description }: OpportunityCardProps) {
  return (
    <Box boxShadow={"2xl"} p="5%" bg="blackAlpha.300">
      <Stack>
        <Heading>{title}</Heading>
        <Text>{description}</Text>
      </Stack>
    </Box>
  );
}
