import {
  Box,
  BoxProps,
  Heading,
  Stack,
  Button,
  Text,
  Link,
  useToast,
} from "@chakra-ui/react";

import { BsLinkedin } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
import { CopyButton } from "@/components/base-components";

export default function ContactUsBadge(
  props: BoxProps & { direction: "row" | "column"; showCopyButton?: boolean }
) {
  const { isMobile } = useCheckMobileScreen();
  const toast = useToast({ position: "top" });
  return (
    <Box {...props} textAlign={"start"}>
      <Box ml="auto" mr="auto">
        <Stack direction={props.direction} justifyContent={"space-evenly"}>
          <Stack direction={"row"} w={props.direction == "row" ? "50%" : ""}>
            <Button
              w="fit-content"
              variant={"menu"}
              size="lg"
              as={Link}
              href="mailto:info@andromedamedicalimaging.com"
              justifyContent={"start"}
              leftIcon={<FiMail />}
              onClick={async () => {
                //copy email to clipboard
                navigator.clipboard
                  .writeText("info@andromedamedicalimaging.com")
                  .then(() => {
                    toast({
                      title: "Email copied to clipboard",
                      status: "info",
                      duration: 1000,
                      isClosable: true,
                    });
                  });
              }}
            >
              {isMobile ? "Email" : "Email: info@andromedamedicalimaging.com"}
            </Button>
            {!isMobile && props.showCopyButton && (
              <CopyButton text="info@andromedamedicalimaging.com" />
            )}
          </Stack>

          <Button
            w={"fit-content"}
            variant={"menu"}
            size="lg"
            as={Link}
            href="https://www.linkedin.com/company/andromeda-medical-imaging-inc"
            justifyContent={"start"}
            target="_blank"
            leftIcon={<BsLinkedin />}
          >
            {isMobile ? "LinkedIn" : "Find us on LinkedIn"}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export function ContactUsBadgeIcons(
  props: BoxProps & { direction: "row" | "column"; showCopyButton?: boolean }
) {
  const { isMobile } = useCheckMobileScreen();
  return (
    <Box {...props} textAlign={"start"}>
      <Box ml="auto" mr="auto">
        <Stack direction={props.direction}>
          <Stack direction={"row"} w={props.direction == "row" ? "50%" : ""}>
            <Button
              w="fit-content"
              variant={"menu"}
              size="lg"
              as={Link}
              href="mailto:info@andromedamedicalimaging.com"
              justifyContent={"start"}
              leftIcon={<FiMail />}
            ></Button>
            {!isMobile && props.showCopyButton && (
              <CopyButton text="info@andromedamedicalimaging.com" />
            )}
          </Stack>

          <Button
            w={"fit-content"}
            variant={"menu"}
            size="lg"
            as={Link}
            href="https://www.linkedin.com/company/andromeda-medical-imaging-inc"
            justifyContent={"start"}
            leftIcon={<BsLinkedin />}
          ></Button>
        </Stack>
      </Box>
    </Box>
  );
}
