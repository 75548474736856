import { Box, Stack, Heading, Icon } from "@chakra-ui/react";

import { ImArrowRight } from "react-icons/im";
import ImageCascade from "./ImageCascade";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

export default function CTandSPIRALImageCascade() {
  const { width } = useCheckMobileScreen();
  return (
    <Stack
      m="10"
      borderRadius={15}
      direction={width < 1000 ? "column" : "row"}
      p={10}
      alignItems={"center"}
    >
      <Box>
        <Heading textAlign={"center"} color="white" pb={10}>
          CT Angiogram
        </Heading>
        <ImageCascade folderName="phase1_contrast_slices" interactive={false} />{" "}
      </Box>
      {!(width < 1000) && (
        <Icon as={ImArrowRight} color="white" fontSize="70px" />
      )}

      {/* <Heading textAlign={"center"}>
      Extract the information you need
    </Heading> */}
      <Box>
        <Heading textAlign={"center"} color="white" pb={10}>
          SPIRAL
        </Heading>
        <ImageCascade folderName="spiral_slices" interactive={false} />{" "}
      </Box>
    </Stack>
  );
}
