import {
  IconButton,
  Text,
  Stack,
  Box,
  Button,
  Icon,
  SimpleGrid,
  useBoolean,
  HStack,
  ButtonGroup,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaBrain } from "react-icons/fa";
import Emphasis from "../base-components/Emphasis";
export default function SampleSelection(props: {
  selectFile: (file: File) => void;
  columns?: number;
  setSelectionInfo: (folderName: string, phase: number) => void;
}) {
  const [sampleFoldersList, setSampleFoldersList] = useState<string[]>([
    "placeholder_1",
    "placeholder_2",
    "placeholder_3",
  ]); // ["P13_CTA1reg.nii.gz","P13_CTA2reg.nii.gz","P13_CTA3reg.nii.gz"]
  const { selectFile, columns, setSelectionInfo } = props;
  useEffect(() => {
    fetch("/spiral/api/sample/list", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response
        .json()
        .then((data) => {
          console.log(data);
          setSampleFoldersList(data.folderNames);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, []);

  function CreateSampleButtons() {
    return sampleFoldersList.map((folderName) => {
      return (
        <SelectSampleButton
          text={
            "Sample Patient " +
            (sampleFoldersList.indexOf(folderName) + 1).toString()
          }
          selectFile={selectFile}
          folderName={folderName}
          setSelectionInfo={setSelectionInfo}
        />
      );
    });
  }
  return (
    <Stack>
      <Text>
        Select a sample patient to view the corresponding{" "}
        <Emphasis>SPIRAL</Emphasis> output.
      </Text>
      <SimpleGrid columns={columns ? columns : 1} spacing={4}>
        {CreateSampleButtons()}
      </SimpleGrid>
    </Stack>
  );
}

interface SelectSampleButtonProps {
  text: string;
  selectFile: (file: File) => void;
  setSelectionInfo: (folderName: string, phase: number) => void;
  folderName: string;
}

function SelectSampleButton(props: SelectSampleButtonProps) {
  const [expanded, setExpanded] = useBoolean();
  const { text, folderName, selectFile, setSelectionInfo } = props;

  function onDone(status: string) {
    console.log(status);
  }
  function requestSample(folderName: string, phase: number) {
    fetch("/spiral/api/sample/" + folderName + "?phase=" + phase, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error("Error processing file");
        }
      })
      .then((blob) => {
        selectFile(new File([blob], "output.nii"));
        onDone("done");
      })
      .catch((error) => {
        console.log(error);
        onDone("error");
      });
    setSelectionInfo(folderName, phase);
  }
  return (
    <Stack>
      <Button
        aria-label={text}
        colorScheme="blue"
        variant={"outline"}
        iconSpacing={3}
        leftIcon={<FaBrain />}
        onClick={() => {
          setExpanded.toggle();
        }}
      >
        <Text>{folderName}</Text>
      </Button>
      {expanded ? (
        <ButtonGroup variant={"outline"} pl={5} pr={5}>
          <Button
            w="100%"
            onClick={() => {
              requestSample(folderName, 1);
            }}
          >
            Phase 1
          </Button>
          <Button
            onClick={() => {
              requestSample(folderName, 2);
            }}
            w="100%"
          >
            Phase 2
          </Button>
          <Button
            onClick={() => {
              requestSample(folderName, 3);
            }}
            w="100%"
          >
            Phase 3
          </Button>
        </ButtonGroup>
      ) : (
        <></>
      )}
    </Stack>
  );
}
