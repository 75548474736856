import { Box, Link, Text, Stack, Heading, Img } from "@chakra-ui/react";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import ContactUsBadge from "@/components/base-components/ContactUsBadge";

const Footer = () => {
  const { isMobile } = useCheckMobileScreen();
  return (
    <Stack
      justifyContent={"center"}
      justifyItems={"center"}
      p={5}
      direction={"column"}
      bg={"white"}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        borderRadius={10}
        bg="white"
        direction={isMobile ? "column" : "row"}
      >
        <Box>
          <Heading pl={5} pr={5} color="brand.blue" textAlign="center">
            Contact Us
          </Heading>
        </Box>

        <ContactUsBadge direction={"column"} />
      </Stack>
      {/* <Text color="black" textAlign={"center"}>
        In affiliation with
      </Text> */}
      <Stack
        justifyContent={"space-evenly"}
        alignContent={"center"}
        alignItems={"center"}
        direction={isMobile ? "column" : "row"}
      >
        <Img
          // as={Link}
          // href="https://www.ucalgary.ca/"
          bg="white"
          maxH="100px"
          // w="50%"
          src={"affiliate-assets/ucalgary_logo.svg"}
        />{" "}
        <Link href="https://hbi.ucalgary.ca/">
          <Img
            src="affiliate-assets/hbi_logo.png"
            maxH="60px"

            //  w="50%"
          />
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
