import { Box, Heading, Link, Stack, Text } from "@chakra-ui/react";
import additonalContent from "@/_content/additional_content.json";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
const SciencePageContent = () => {
  const { publications } = additonalContent;
  const { isMobile } = useCheckMobileScreen();
  return (
    <Box>
      <Box p={isMobile ? "" : "10%"}>
        <Heading color="brand.blue">Publications</Heading>
        <Stack>
          {publications.map((publication) => (
            <PublicationItem
              title={publication.title}
              abstract={publication.abstract}
              authors={publication.authors}
              url={publication.url}
              date={publication.date}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default SciencePageContent;

interface PublicationItemProps {
  title: string;
  abstract: string;
  authors: string[];
  url: string;
  date: string;
}
function PublicationItem({ title, url, authors, date }: PublicationItemProps) {
  return (
    <Box bg="blackAlpha.800" p={5}>
      <Text color="white">{date}</Text>
      <Link fontSize="25px" href={url} color="white">
        {title}
      </Link>
      <Text color="white">{authors.join(", ")}</Text>
    </Box>
  );
}
