import {
  useToast,
  SimpleGrid,
  GridItem,
  Heading,
  ButtonGroup,
  Button,
  Icon,
  Text,
  HStack,
  Box,
  Stack,
  Menu,
  MenuButton,
  Select,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { ImArrowRight2 } from "react-icons/im";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import NiftiViewer from "../nifti-viewer/NiftiViewer";
import SPIRALDemoOutput from "./SPIRALDemoOutput";
import SampleSelection from "./SampleSelection";
import Emphasis from "../base-components/Emphasis";
/**
 * Wrapper component for the SPIRAL demo
 * @returns
 */
export default function SPIRALTechDemo() {
  const { isMobile, width } = useCheckMobileScreen();
  const toast = useToast();
  const [inputNIFTI, setInputNIFTI] = useState<File | null>(null);
  const [inputMode, setInputMode] = useState<"Multiphase" | "Singlephase">(
    "Multiphase"
  );
  const [outputMode, setOutputMode] = useState<
    "Probability Map" | "Core and Penumbra"
  >("Probability Map");
  const [processingState, setProcessingState] = useState<
    "idle" | "processing" | "done"
  >("idle");
  const [selectionInfo, setSelectionInfo] = useState<{
    folderName: string;
    phase: number;
  }>({ folderName: "", phase: 0 });
  function onProcessingComplete(result: "done" | "error") {
    if (result === "done") {
      setProcessingState("done");
    } else {
      toast({
        title: "Error processing file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setProcessingState("idle");
    }
  }
  return (
    <>
      <Heading textAlign={"center"}>SPIRAL Demo</Heading>

      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <Tooltip
                label="Coming soon..."
                fontSize="md"
                placement="auto-start"
              >
                <AccordionButton pl={5} pt={5}>
                  <Heading fontSize={"20px"}>Mode Selection</Heading>
                  <Spacer />
                  {isExpanded ? (
                    <></>
                  ) : (
                    <Text>
                      <Emphasis>Input:</Emphasis> {inputMode} to{" "}
                      <Emphasis>Output:</Emphasis> {outputMode}
                    </Text>
                  )}
                </AccordionButton>
              </Tooltip>
              <AccordionPanel>
                <SimpleGrid columns={2}>
                  <GridItem>
                    <Text>Input Mode</Text>
                    <Select
                      onChange={(evt) => {
                        setInputMode(
                          evt.target.value as "Multiphase" | "Singlephase"
                        );
                      }}
                    >
                      <option value="multiphase">Multiphase</option>
                      <option value="singlephase">
                        Single CTA and non-contrast CT
                      </option>
                    </Select>
                  </GridItem>
                  <GridItem>
                    <Text>Output Mode</Text>
                    <Select
                      onChange={(evt) => {
                        setOutputMode(
                          evt.target.value as
                            | "Probability Map"
                            | "Core and Penumbra"
                        );
                      }}
                    >
                      <option value="probability">Probability Map</option>
                      <option value="corepenumbra">Core vs Penumbra</option>
                    </Select>
                  </GridItem>
                </SimpleGrid>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <Tooltip
                label="The data comes from real patients, but the data has been anonymized, so you can safely use it!"
                fontSize="md"
                placement="auto-start"
              >
                <AccordionButton pl={5} pt={5}>
                  <Heading fontSize={"20px"}>Sample Data</Heading>

                  <Spacer />
                  {isExpanded ? (
                    <></>
                  ) : (
                    <Text>
                      <Emphasis>Files Uploaded:</Emphasis>{" "}
                      {inputNIFTI ? inputNIFTI.name : "None"}
                    </Text>
                  )}
                </AccordionButton>
              </Tooltip>
              <AccordionPanel>
                <SampleSelection
                  selectFile={setInputNIFTI}
                  columns={isMobile ? 1 : 3}
                  setSelectionInfo={(folderName, phase) => {
                    setSelectionInfo({ folderName, phase });
                  }}
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>

      <SimpleGrid
        columns={width <= 1024 + 100 ? 1 : 2}
        spacing={5}
        p={"50px"}
        h="100%"
      >
        <GridItem colSpan={1} justifySelf={"center"}>
          <Heading textAlign={"center"}>Input</Heading>
          {/* {inputNIFTI ? <></> :

            <UploadNifti onFileAccepted={(file) => {
              setInputNIFTI(file);
              console.log(`accepted ${file.name}`);
            }} />
          } */}
          {/* {inputNIFTI ? */}
          <NiftiViewer
            file={inputNIFTI}
            placeholder={
              <Text color="white" textAlign={"center"}>
                Select one of the sample patients in the{" "}
                <Emphasis>Sample Data</Emphasis> tab above to generate a SPIRAL
                map in realtime!{" "}
              </Text>
            }
          />
          {/* : <></>} */}
          <HStack justifyContent={"center"}>
            {inputNIFTI ? (
              <Button
                variant={"outline"}
                colorScheme="red"
                onClick={() => {
                  setInputNIFTI(null);
                }}
              >
                Clear
              </Button>
            ) : (
              <></>
            )}
            <Button
              bg="brand.blue"
              color="white"
              _hover={{ bg: "brand.offBlue" }}
              onClick={() => {
                if (!inputNIFTI) {
                  toast({
                    duration: 1000,
                    title: "No file selected",
                    description: "Please select a file first",
                    status: "error",
                    variant: "top-accent",
                    // render: () => (
                    //   <Box bg="red.400" color="white" p={'30px'}>
                    //     <Text>Upload a file first!</Text>

                    //   </Box>
                    // )
                  });
                  return;
                }

                setProcessingState("processing");
              }}
            >
              <Text>Demonstrate SPIRAL</Text>
              <Icon as={ImArrowRight2} fontSize={"24x"} />
            </Button>
          </HStack>
        </GridItem>

        <GridItem colSpan={1} justifySelf={"center"}>
          <Heading textAlign={"center"}>Output</Heading>

          <SPIRALDemoOutput
            //TODO reimplement proper interface later
            //input={inputNIFTI}

            input={selectionInfo}
            processingState={processingState}
            onDone={onProcessingComplete}
          />
        </GridItem>
      </SimpleGrid>
      <Text
        width="100%"
        pl={width > 900 ? "200px" : "10px"}
        pr={width > 900 ? "200px" : "10px"}
      >
        This is a demonstration of the SPIRAL algorithm, it is not meant to be
        used clinically in this state. <br />
        {/* Upload a registered, multiphase NIFTI file to see the results of the
        algorithm. */}
      </Text>
    </>
  );
}
