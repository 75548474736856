import {
  Box,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { BsLinkedin } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import teamJson from "@/_content/team.json";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
import { useEffect, useState } from "react";
import { motion, useScroll } from "framer-motion";
import { TeamMember } from "@/interfaces";
import { TeamMemberDisplay } from "@/components/base-components";
interface ContactPageContentProps {
  options?: {
    includeEmails?: boolean;
  };
}
export default function AboutUsPage(props: ContactPageContentProps) {
  const { scrollY } = useScroll();
  const team = teamJson.members as TeamMember[];
  const { isMobile, width } = useCheckMobileScreen();
  const { options } = props;
  const { includeEmails } = options ? options : { includeEmails: false };
  const [startScrolling, setStartScrolling] = useBoolean(false);
  const floatingVariants = {
    top: {
      opacity: 0,
      //height: isMobile ? '50px' : '120px',
      transition: { duration: 0.6 },
    },
    scroll: {
      opacity: 1,
      //height: isMobile ? '50px' : '70px',
      transition: { duration: 0.6 },
    },
  };
  function update() {
    if (scrollY?.get() > scrollY?.getPrevious() && scrollY?.get() > 200) {
      setStartScrolling.on();
    } else if (
      scrollY?.get() < 200 &&
      scrollY?.get() < scrollY?.getPrevious()
    ) {
      setStartScrolling.off();
    }
  }

  useEffect(() => {
    return scrollY?.on("change", update);
  }, []);
  function createContacts() {
    return team.map((member, key) => {
      if (member.show) {
        return (
          <GridItem key={key}>
            <TeamMemberDisplay
              contact={member}
              imageSize={isMobile ? (width - 100) / 2 : (width - 100) / 6 - 20}
            />
          </GridItem>
        );
      } else return <></>;
    });
  }
  return (
    <Box>
      <Box pt="5%" pl={"10%"} pr={"10%"} bg="brand.midnightGreen">
        <Heading textAlign="left" p={2} color={"white"}>
          The Team
        </Heading>

        <SimpleGrid
          p={"2%"}
          columns={isMobile ? 1 : Math.floor(width / 450)}
          spacing={2}
        >
          {createContacts()}
        </SimpleGrid>
        <Heading textAlign="left" p={2} color={"white"}>
          Other Contributors
        </Heading>

        <SimpleGrid
          p={"2%"}
          columns={isMobile ? 1 : Math.floor(width / 450)}
          spacing={2}
        >
          <GridItem borderRadius={10} bg="blackAlpha.800" p={5}>
            <Link
              color="white"
              textDecor={"underline"}
              fontSize={"30px"}
              href="https://ucalgary.ca/labs/miplab/home"
            >
              Medical Image Processing and Machine Learning Laboratory
            </Link>
            <Text fontSize={"2xl"}>
              This lab has made important contributions to the validation of
              SPIRAL.
            </Text>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
