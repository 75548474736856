import {
  Box,
  Button,
  Center,
  GridItem,
  HStack,
  Heading,
  Icon,
  Img,
  Link,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import Emphasis from "@/components/base-components/Emphasis";
import { ImArrowRight } from "react-icons/im";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
import { AndromedaLogoText } from "@/pages/main-layout/MainNavBar";
import { motion } from "framer-motion";
import { BsPatchQuestionFill } from "react-icons/bs";
import SlideInSection from "@/components/base-components/SlideInSection";
import SpiralTM from "@/components/base-components/SpiralTM";
import CTandSPIRALImageCascade from "@/components/content-components/CTandSPIRALImageCascade";
export default function LandingPage() {
  const { isMobile, width } = useCheckMobileScreen();
  return (
    <Box
      //backgroundImage={"mri-scans-of-the-brain-splash.png"}
      backgroundAttachment={"fixed"}
      backgroundPosition={"center"}
      backgroundSize={"cover"}
      backgroundRepeat={"no-repeat"}
    >
      <Center p={5} h="300px">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
          }}
        >
          {/* <AndromedaLogoButton />
           */}
          <AndromedaLogoText position={"sticky"} />
        </motion.div>
      </Center>

      {/* <Box height={'100px'} /> */}
      {/* <Box
        bg="blackAlpha.900"
        pt={5}
        pb={0}
        pl={width > 900 ? "200px" : "10px"}
        pr={width > 900 ? "200px" : "10px"}
      ></Box> */}
      {/* <Box height={'100px'} /> */}
      <Box
        bg="brand.gunmetal"
        pb={5}
        // pl={width > 1000 ? "200px" : "10px"}
        // pr={width > 1000 ? "200px" : "10px"}
      >
        <SlideInSection direction="left">
          <Box
            backgroundImage={"SPIRAL_demo_hand.jpg"}
            backgroundAttachment={""}
            backgroundPosition={"right"}
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
          >
            <SimpleGrid
              backdropFilter={"blur(3px)"}
              //backdrop filter that darkens the background
              bg={"blackAlpha.500"}
              p={isMobile ? "15px" : "100px"}
              columns={width < 1300 ? 1 : 2}
              spacing={10}
            >
              <GridItem>
                <Text fontSize={`35px`} fontWeight={"semibold"}>
                  <Text>Fast.</Text>
                  <Text>Simple.</Text>
                  <Text>Accessible.</Text>
                  <Text>
                    <Text color={"brand.blue"} as="b" fontSize={`40px`}>
                      Better Imaging{" "}
                    </Text>
                    for
                    <Text color={"brand.blue"} as="b" fontSize={`40px`}>
                      {" "}
                      Better Stroke Care
                    </Text>
                    .
                  </Text>
                </Text>

                <Spacer h="30px" />
                <Button
                  borderRadius={1}
                  pt={3}
                  pb={3}
                  pl={"50px"}
                  pr={"50px"}
                  fontSize={"20px"}
                  bg="brand.darkBlue"
                  color="white"
                  fontWeight={"700"}
                  _active={{
                    bg: "brand.blue",
                  }}
                  _hover={{
                    bg: "brand.offBlue",
                    textDecor: "none",
                  }}
                  h="fit-content"
                  as={Link}
                  href="/spiral"
                  whiteSpace="normal"
                >
                  See SPIRAL in action on your browser!
                </Button>
                <Text
                  color="white"
                  lineHeight={"tall"}
                  fontSize={"25px"}
                  fontWeight={"semibold"}
                >
                  Andromeda Medical Imaging uses research and treatment
                  experience from the world-class Calgary Stroke Program to
                  develop <Emphasis>stroke diagnostic software</Emphasis>.
                </Text>
              </GridItem>
            </SimpleGrid>
          </Box>
        </SlideInSection>
        <Box
          ml={isMobile ? "" : "15%"}
          mr={isMobile ? "" : "15%"}
          pt={10}
          pb={10}
        >
          <Text
            m="auto"
            maxW="1000px"
            lineHeight={"tall"}
            p={5}
            fontSize={"30px"}
            textAlign={"center"}
          >
            {"The "}
            <Link
              color="brand.offBlue"
              fontWeight={"900"}
              href="/spiral"
              textDecor={"underline"}
              variant={"subtle"}
            >
              Simple Perfusion Reconstruction Algorithm (<SpiralTM />)
            </Link>{" "}
            {`is our patented flagship algorithm. It is the first and only
            solution that provides perfusion imaging from a helical CTA scan.`}
          </Text>
        </Box>
        <Box bg="blackAlpha.700" pb={3}>
          <CTandSPIRALImageCascade />
        </Box>
        <Spacer h="50px" />
        <ClinicalMetricsSection />
        <br />
        <br />
        {/* <Box pl={"15%"} pr="15%" bg="white" pt={5} pb={5}>
          <Heading color="brand.blue" textAlign={"center"}>
            Get In Touch!
          </Heading>
          <ContactUsBadge direction="row" />
        </Box> */}
      </Box>
    </Box>
  );
}

function ClinicalMetricsSection() {
  const { isMobile } = useCheckMobileScreen();
  return (
    <Box
      color="white"
      fontSize={"20px"}
      pl={isMobile ? 3 : "100px"}
      pr={isMobile ? 3 : "100px"}
      textAlign={isMobile ? "center" : "inherit"}
    >
      <Text textAlign={"center"} fontSize={"25px"}>
        Our software provides these{" "}
        <Text as={"b"} color="brand.blue">
          key
        </Text>{" "}
        metrics:
      </Text>
      <br />
      <SimpleGrid columns={isMobile ? 1 : 3} spacing={4}>
        <GridItem textAlign={"center"} w={"100%"}>
          <Text
            as="b"
            color="brand.offBlue"
            textAlign={"center"}
            w={"100%"}
            fontSize={"30px"}
          >
            Brain Tissue Perfusion
          </Text>
          <Text textAlign={"left"}>
            <SpiralTM /> quantifies cerebral perfusion distilled from a
            three-phase CTA, allowing the clinician to quickly assess the key
            areas of hypoperfusion.
          </Text>
        </GridItem>
        <GridItem w={"100%"} textAlign={"center"}>
          <Text
            as="b"
            color="brand.offBlue"
            textAlign={"center"}
            w="100%"
            fontSize={"30px"}
          >
            Treatment Response Probability
          </Text>
          <Text textAlign={"left"}>
            <SpiralTM /> produces probabilitistic maps distinguishing 'infarct
            core' and 'penumbral' regions, reflecting the likelihood the brain
            tissue can be saved with treatment intervention.
          </Text>
        </GridItem>
        <GridItem w={"100%"} textAlign={"center"}>
          <Text
            as="b"
            color="brand.offBlue"
            textAlign={"center"}
            w="100%"
            fontSize={"30px"}
          >
            Clot Location
          </Text>
          <Text textAlign={"left"}>
            <SpiralTM /> locates Large Vessel Occlusions (LVO) and Medium Vessel
            Occlusions (MeVO), corroborating the clinician's diagnosis.
          </Text>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
}
export function WhyChooseUsSection() {
  return (
    <Box>
      <Heading textAlign={"center"}>Why choose us?</Heading>
      Many primary stroke centers cannot justify the hardware (a cine CT
      scanner) required for CT perfusion or the expensive software licenses.
      <br />
      SPIRAL uses hardware that already exists and is computationally
      inexpensive, providing a simpler, cheaper, and faster alternative.
      <br />
      <br />
    </Box>
  );
}
export function Definition(props: { text: string; definition: string }) {
  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button variant="text">{props.text}</Button>
      </PopoverTrigger>
      <PopoverContent bg="blackAlpha.900">
        <PopoverArrow bg="blackAlpha.900" />
        {/* <PopoverCloseButton /> */}

        <PopoverBody>
          <Text color="white" fontSize="18px">
            {props.definition}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export function MoreInformationIcon(props: { text: string }) {
  return (
    <Tooltip label={props.text} aria-label={props.text}>
      <Icon as={BsPatchQuestionFill} />
    </Tooltip>
  );
}
