import {
  Box,
  useToast,
  ButtonGroup,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Heading,
  Spacer,
  Img,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  SimpleGrid,
  GridItem,
  Icon,
  Text,
  Center,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import { useState } from "react";
import { isValidMotionProp, motion } from "framer-motion";
import { BsChevronCompactDown } from "react-icons/bs";
import { ImArrowRight2 } from "react-icons/im";
import { SPIRALInformation } from "@/components/content-components";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
import SPIRALTechDemo from "@/components/spiral-demo";

export default function SPIRALPageContent() {
  const { isMobile, width } = useCheckMobileScreen();
  const [expandIndex, setExpandIndex] = useState<number[]>([0]);
  return (
    <Box>
      <HStack bg="brand.blue">
        <Heading
          textAlign={"center"}
          p={5}
          color={"white"}
          fontSize={isMobile ? 40 : 60}
          w="100%"
          h={"100%"}
        >
          SPIRAL
        </Heading>
        <Spacer />
      </HStack>
      {/* <Accordion
        backgroundImage={"mri-scans-of-the-brain-splash.png"}
        backgroundAttachment={"fixed"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        defaultIndex={[0]}
        allowToggle
        index={expandIndex}
      > */}

      <Box p={4} bg="black" color={"white"}>
        {" "}
        <SPIRALInformation />
      </Box>
      {/* <Box bg="brand.darkBlue" w="100%" h="2vh" /> */}
      <Box p={4} bg="blackAlpha.900" color={"white"}>
        <SPIRALTechDemo />
      </Box>
    </Box>
  );
}
export function SPIRALPageContentPrevious() {
  const { isMobile } = useCheckMobileScreen();
  const [expandIndex, setExpandIndex] = useState<number[]>([0]);
  return (
    <Box>
      <HStack bg="brand.blue">
        <Heading
          textAlign={"center"}
          p={5}
          color={"white"}
          fontSize={isMobile ? 40 : 60}
          w="100%"
          h={"100%"}
        >
          {isMobile ? "SPIRAL Demo" : "SPIRAL Demonstration"}
        </Heading>
        <Spacer />
      </HStack>
      <Accordion
        backgroundImage={"mri-scans-of-the-brain-splash.png"}
        backgroundAttachment={"fixed"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        defaultIndex={[0]}
        allowToggle
        index={expandIndex}
      >
        <AccordionItem border={"0px"}>
          <h2>
            <AccordionButton
              bg="brand.darkBlue"
              color="white"
              _hover={{
                bg: "brand.offBlue",
                color: "white",
              }}
              onClick={() => {
                if (expandIndex.includes(0)) {
                  setExpandIndex(expandIndex.filter((i) => i !== 0));
                } else {
                  setExpandIndex([...expandIndex, 0]);
                }
              }}
            >
              <Heading>What is SPIRAL?</Heading>
              <Spacer />
              <AccordionIcon fontSize={"40px"} />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} bg="blackAlpha.900" color={"white"}>
            <SPIRALInformation />
            <Center>
              {/* <MotionButton
                bg="brand.blue"
                p={4}
                color="white"
                borderRadius={20}
                animate={{ scale: [1.1, 1, 1, 1.1] }}
                //@ts-ignore no problem, merge conflict
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "loop",
                }}
                size='lg'
                onClick={() => {
                  setExpandIndex([1])
                }}
              >
                See it in action
              </MotionButton> */}
            </Center>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton
              bg="brand.darkBlue"
              color={"white"}
              _hover={{
                bg: "brand.offBlue",
                color: "white",
              }}
              onClick={() => {
                if (expandIndex.includes(1)) {
                  setExpandIndex(expandIndex.filter((i) => i !== 1));
                } else {
                  setExpandIndex([...expandIndex, 1]);
                }
              }}
            >
              <Heading>Tech Demo</Heading>
              <Spacer />
              <AccordionIcon fontSize={"40px"} />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} bg="blackAlpha.900" color={"white"}>
            <SPIRALTechDemo />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
export const MotionButton = chakra(motion.button, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});
