import { NIFTI1, NIFTI2 } from "nifti-reader-js";
import { useRef, useState, useEffect } from "react";
import Color from "../../interfaces/color";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
interface NiftiCanvasProps {
  niftiheader: NIFTI1 | NIFTI2;
  niftiimage: ArrayBuffer;
  slice: number;
  colors: Color[];
}

export default function NiftiCanvas(props: NiftiCanvasProps) {
  const {
    niftiheader: niftiHeader,
    niftiimage: niftiImage,
    slice,
    colors,
  } = props;
  //for now we just take the first two colors in the array
  const { width } = useCheckMobileScreen();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dimensions, setDimensions] = useState({ cols: 0, rows: 0 });
  const [scale, setScale] = useState(1);
  useEffect(() => {
    setScale(width < 512 ? 0.75 : 1);
  }, [width]);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      // if (context) {
      //   if (dimensions.cols !== 0 || dimensions.rows !== 0) {
      //     drawCanvas(context, 0, dimensions);
      //   }
      // }
    }
  }, []);
  useEffect(() => {
    setDimensions({
      cols: niftiHeader.dims[1],
      rows: niftiHeader.dims[2],
    });
  }, [niftiHeader]);

  useEffect(() => {
    //draw the canvas once when the data changes
    const canvas = canvasRef.current;
    if (canvas == null) return;
    // get nifti dimensions

    // console.log(dimensions)

    // set canvas dimensions to nifti slice dimensions
    canvas.width = dimensions.cols * scale;
    canvas.height = dimensions.rows * scale;
    const context = canvas.getContext("2d");
    if (context) {
      if (dimensions.cols !== 0 || dimensions.rows !== 0) {
        drawCanvas(context, slice, dimensions, scale);
      }
    }
  }, [niftiImage, slice, colors, canvasRef.current, scale]);

  function lerp(a: number, b: number, t: number) {
    return a + t * (b - a);
  }

  function lerpColor(a: Color, b: Color, t: number): Color {
    return {
      r: lerp(a.r, b.r, t),
      g: lerp(a.g, b.g, t),
      b: lerp(a.b, b.b, t),
    };
  }

  function calculateColor(
    value: number,
    min: number,
    max: number,
    colors: Color[]
  ): Color {
    const realMax = max - min;
    const realValue = value - min;
    const segmentLength = realMax / (colors.length - 1);

    const lowerBound = Math.floor(realValue / segmentLength);
    const upperBound = Math.ceil(realValue / segmentLength);

    const lowerColor = colors[lowerBound];
    const upperColor = colors[upperBound];
    const ratio = realValue / realMax;
    if (colors.length <= 2) {
      return {
        r: ratio * 255,
        g: ratio * 255,
        b: ratio * 255,
      };
    }
    //console.log(`value: ${value / segmentLength}, lower: ${lowerBound}, upper: ${upperBound}`)
    return {
      r: lowerColor.r + (upperColor.r - lowerColor.r) * ratio,
      g: lowerColor.g + (upperColor.g - lowerColor.g) * ratio,
      b: lowerColor.b + (upperColor.b - lowerColor.b) * ratio,
    };
  }

  async function drawCanvas(
    ctx: CanvasRenderingContext2D,
    slice: number,
    dimensions: { cols: number; rows: number },
    scale?: number
  ) {
    if (!scale) scale = 1;
    const { cols, rows } = dimensions;
    var canvasImageData = ctx.createImageData(cols, rows);

    // convert raw data to typed array based on nifti datatype
    var typedData;

    if (niftiHeader.datatypeCode === NIFTI1.TYPE_UINT8) {
      typedData = new Uint8Array(niftiImage);
    } else if (niftiHeader.datatypeCode === NIFTI1.TYPE_INT16) {
      typedData = new Int16Array(niftiImage);
    } else if (niftiHeader.datatypeCode === NIFTI1.TYPE_INT32) {
      typedData = new Int32Array(niftiImage);
    } else if (niftiHeader.datatypeCode === NIFTI1.TYPE_FLOAT32) {
      typedData = new Float32Array(niftiImage);
    } else if (niftiHeader.datatypeCode === NIFTI1.TYPE_FLOAT64) {
      typedData = new Float64Array(niftiImage);
    } else if (niftiHeader.datatypeCode === NIFTI1.TYPE_INT8) {
      typedData = new Int8Array(niftiImage);
    } else if (niftiHeader.datatypeCode === NIFTI1.TYPE_UINT16) {
      typedData = new Uint16Array(niftiImage);
    } else if (niftiHeader.datatypeCode === NIFTI1.TYPE_UINT32) {
      typedData = new Uint32Array(niftiImage);
    } else {
      return;
    }

    //find max in typedData
    var maxBit = 0;
    var maxVal = 0;
    var minVal = 0;
    for (var i = 0; i < typedData.length; i++) {
      if (typedData[i] > maxVal) {
        maxVal = typedData[i];
      }
    }
    for (var i = 0; i < typedData.length; i++) {
      if (typedData[i] < minVal) {
        minVal = typedData[i];
      }
    }
    console.log(`Min in slice: ${minVal}`);
    console.log(`Max in slice: ${maxVal}`);
    console.log(`Segment length: ${maxVal / colors.length}`);
    maxBit = typedData[i] & 0xff;
    // console.log(max & 0xff)
    // offset to specified slice
    var sliceSize = cols * rows;
    var sliceOffset = sliceSize * slice;

    // create imaging data
    for (var row = rows - 1; row >= 0; row--) {
      var rowOffset = row * cols;
      var rowOffsetInverse = (rows - row - 1) * cols;
      for (var col = 0; col < cols; col++) {
        var offset = sliceOffset + rowOffset + col;
        var value = typedData[offset];
        var columnInverse = cols - col - 1;
        const rawValue = value & 0xff;

        /* 
           Assumes data is 8-bit, otherwise you would need to first convert 
           to 0-255 range based on datatype range, data range (iterate through
           data to find), or display range (cal_min/max).
           
           Other things to take into consideration:
             - data scale: scl_slope and scl_inter, apply to raw value before 
               applying display range
             - orientation: displays in raw orientation, see nifti orientation 
               info for how to orient data
             - assumes voxel shape (pixDims) is isometric, if not, you'll need 
               to apply transform to the canvas
             - byte order: see littleEndian flag
        */
        const pixelColor = calculateColor(value, minVal, maxVal, colors);
        // canvasImageData.data[(rowOffset + col) * 4] = pixelColor.r;
        // canvasImageData.data[(rowOffset + col) * 4 + 1] = pixelColor.g;
        // canvasImageData.data[(rowOffset + col) * 4 + 2] = pixelColor.b;
        // canvasImageData.data[(rowOffset + col) * 4 + 3] = 255;
        canvasImageData.data[(rowOffsetInverse + columnInverse) * 4] =
          pixelColor.r;
        canvasImageData.data[(rowOffsetInverse + columnInverse) * 4 + 1] =
          pixelColor.g;
        canvasImageData.data[(rowOffsetInverse + columnInverse) * 4 + 2] =
          pixelColor.b;
        canvasImageData.data[(rowOffsetInverse + columnInverse) * 4 + 3] = 255;
      }
    }
    const bitmap = await createImageBitmap(canvasImageData);
    ctx.drawImage(bitmap, 0, 0, cols * scale, rows * scale);
  }

  return (
    <>
      <canvas></canvas>
      <canvas ref={canvasRef} width={"100%"} height={"100%"} style={{}} />
    </>
  );
}
